/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Col, Form, Button, Image, Container, FormGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Loading from '../Loading';
import { getBookingDisclaimersDetailsService } from '../../services/bookingsService';
import { addSignedContractService } from '../../services/signedContractsService';
import { editContactDetailsService } from '../../services/contactServices';
import './index.scss';
import { convertQueryStringToObject, dispatchModal } from '../../utils/helper';
import _ from 'lodash';

const BookingDesclaimer = (props) => {
    const history = useHistory();
    const queryObj = convertQueryStringToObject(window.location.search);
    const bookingId = queryObj.bid;
    const ts = queryObj.ts;

    const { handleSubmit } = useForm();
    const [loading, setLoading] = useState(true);
    const [bookingID, setBookingId] = useState('');
    const [bookingCreatedTimeStamp, setBookingCreatedTimeStamp] = useState('');
    const [tripSetting, setTripSetting] = useState({});
    const [disclaimer, setDisclaimer] = useState({});
    const [contactDetails, setContactDetail] = useState({});
    const [termsCondition, setTermsCondition] = useState({});
    const [disable, setDisable] = useState(true);
    const [companyId, setCompanyId] = useState('');
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [top, setTop] = useState('');
    const [bottom, setBottom] = useState('');
    const [isDisclaimer, setIsDisclaimer] = useState(false);
    const [isTerms, setIsTerms] = useState(false);

    const disclaimerValidation = (disclaimerSettings, disclaimer) => {
        let display = true;
        if (_.isEmpty(disclaimer)) {
            display = false;
        } else if (
            Number(disclaimerSettings.disclaimerWaiveSign) ||
            contactDetails.Organization.overrideDisclaimer === 1
        ) {
            display = false;
        } else if (Number(disclaimerSettings.disclaimerAlwaysSign)) {
            display = true;
        } else if (
            moment().diff(moment(disclaimerSettings.disclaimerLastAcceptedAt), 'days') >
            disclaimerSettings.disclaimerDayExpiration
        ) {
            display = true;
        } else if (
            disclaimerSettings.disclaimerVersionExpiration == 1 &&
            Number(contactDetails.lastAcceptedDisclaimerID) !== Number(disclaimer.id)
        ) {
            display = true;
        }
        setIsDisclaimer(display);
        return display;
    };

    const termsValidation = (termsAndConditionSettings, termsCondition) => {
        let display = true;
        if (_.isEmpty(termsCondition)) {
            display = false;
        } else if (
            Number(termsAndConditionSettings.termsConditionsWaiveSign) ||
            contactDetails.Organization.overrideTermsConditions === 1
        ) {
            display = false;
        } else if (Number(termsAndConditionSettings.termsConditionsAlwaysSign)) {
            display = true;
        } else if (
            moment().diff(moment(termsAndConditionSettings.termsConditionsAcceptedAt), 'days') >
            Number(termsAndConditionSettings.termsConditionsDayExpiration)
        ) {
            display = true;
        } else if (
            Number(termsAndConditionSettings.termsConditionsVersionExpiration) == 1 &&
            Number(contactDetails.lastAcceptedTermsConditionsID) !== Number(termsCondition.id)
        ) {
            display = true;
        }
        setIsTerms(display);
        return display;
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                let booking = await getBookingDisclaimersDetailsService(bookingId, ts);
                if (booking && Object.keys(booking.data).length > 0) {
                    let bookingData = booking.data;
                    let companyId = bookingData.details.companyId;
                    setCompanyId(companyId);
                    setBookingId(bookingData.details.bookingId);

                    const createdAtTimeStamp = new Date(bookingData.details.createdAt).valueOf();
                    setBookingCreatedTimeStamp(createdAtTimeStamp);

                    let contact = bookingData?.contactDetail;
                    setContactDetail(contact);

                    let tripSetting = bookingData?.accountSettings;
                    setTripSetting(tripSetting);

                    let desclaimers = bookingData?.disclaimerData;
                    let desclaimer, termsCondition;
                    if (desclaimers && Array.isArray(desclaimers) && desclaimers.length > 0) {
                        desclaimers = desclaimers.filter(
                            (d) => Number(d.companyID) === Number(companyId) && d.isDefault === 1
                        );
                        desclaimer =
                            Array.isArray(desclaimers) && desclaimers.length > 0
                                ? desclaimers.reduce((prev, current) => (prev.id > current.id ? prev : current))
                                : null;
                        if (desclaimer) setDisclaimer(desclaimer);
                    }
                    let tc = bookingData?.termsData;
                    if (tc && Array.isArray(tc) && tc.length > 0) {
                        tc = tc.filter((t) => Number(t.companyID) === Number(companyId) && t.isDefault === 1);
                        termsCondition =
                            Array.isArray(tc) && tc.length > 0
                                ? tc.reduce((prev, current) => (prev.id > current.id ? prev : current))
                                : null;
                        if (termsCondition) setTermsCondition(termsCondition);
                    }

                    let disclaimerSettings = {
                        disclaimerLastAcceptedAt: contact?.disclaimerLastAcceptedAt,
                    };

                    let termsAndConditionSettings = {
                        termsConditionsAcceptedAt: contact?.termsConditionsAcceptedAt,
                    };

                    if (contact.overrideSignatureSettings) {
                        disclaimerSettings = {
                            ...disclaimerSettings,
                            disclaimerDayExpiration: contact.disclaimerDayExpiration,
                            disclaimerVersionExpiration: contact.disclaimerVersionExpiration,
                            disclaimerAlwaysSign: contact.disclaimerAlwaysSign,
                            disclaimerWaiveSign: contact.disclaimerWaiveSign,
                        };
                        termsAndConditionSettings = {
                            ...termsAndConditionSettings,
                            termsConditionsDayExpiration: contact.termsConditionsDayExpiration,
                            termsConditionsVersionExpiration: contact.termsConditionsVersionExpiration,
                            termsConditionsAlwaysSign: contact.termsConditionsAlwaysSign,
                            termsConditionsWaiveSign: contact.termsConditionsWaiveSign,
                        };
                    } else {
                        disclaimerSettings = {
                            ...disclaimerSettings,
                            disclaimerDayExpiration: tripSetting.disclaimerDayExpiration,
                            disclaimerVersionExpiration: tripSetting.disclaimerVersionExpiration,
                            disclaimerAlwaysSign: tripSetting.disclaimerAlwaysSign,
                            disclaimerWaiveSign: tripSetting.disclaimerWaiveSign,
                        };
                        termsAndConditionSettings = {
                            ...termsAndConditionSettings,
                            termsConditionsDayExpiration: tripSetting.termsConditionsDayExpiration,
                            termsConditionsVersionExpiration: tripSetting.termsConditionsVersionExpiration,
                            termsConditionsAlwaysSign: tripSetting.termsConditionsAlwaysSign,
                            termsConditionsWaiveSign: tripSetting.termsConditionsWaiveSign,
                        };
                    }

                    let disclaimerCheck = !disclaimerValidation(disclaimerSettings, desclaimer ? desclaimer : {});
                    let termsCheck = !termsValidation(termsAndConditionSettings, termsCondition ? termsCondition : {});

                    if (disclaimerCheck && termsCheck) {
                        setShowDisclaimer(false);
                        history.push({
                            pathname: '/p/booking-confirmation',
                            search: `?bid=${bookingId}&ts=${createdAtTimeStamp}`, // query string
                        });
                    } else setShowDisclaimer(true);
                } else {
                    dispatchModal('Booking Record Not Found.');
                }
                setLoading(false);
            } catch (e) {
                console.log('Error: ', e);
            }
            setLoading(false);
        })();
    }, [bookingId, history, ts]);

    const handleSave = async () => {
        await handleSubmit(async (data) => {
            try {
                let signedContract = {},
                    contact = {};
                if (contactDetails) {
                    if (
                        contactDetails.overrideDisclaimer === 0 &&
                        //disclaimer Version Changed
                        Number(contactDetails.lastAcceptedDisclaimerID) !== Number(disclaimer.id) &&
                        contactDetails.overrideTermsConditions === 0 &&
                        //terms Version Changed
                        Number(contactDetails.lastAcceptedTermsConditionsID) !== Number(termsCondition.id) &&
                        !_.isEmpty(disclaimer) &&
                        !_.isEmpty(termsCondition)
                    ) {
                        // If both disc and terms exist and are not overridden
                        signedContract = {
                            bookingID: bookingId,
                            disclaimerID: disclaimer.id,
                            disclaimerInitials: top,
                            disclaimerInitialedAt: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
                            termsConditionsID: termsCondition.id,
                            termsConditionsInitials: bottom,
                            termsConditionsInitialedAt: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
                        };
                        contact = {
                            lastAcceptedDisclaimerID: disclaimer.id,
                            disclaimerLastAcceptedAt: moment().format('YYYY-MM-DDTHH:mm:00'),
                            lastAcceptedTermsConditionsID: termsCondition.id,
                            termsConditionsAcceptedAt: moment().format('YYYY-MM-DDTHH:mm:00'),
                            overrideDisclaimer: contactDetails.overrideDisclaimer,
                            overrideTermsConditions: contactDetails.overrideTermsConditions,
                        };
                    } else if (
                        !_.isEmpty(disclaimer) &&
                        contactDetails.overrideDisclaimer === 0 &&
                        Number(contactDetails.lastAcceptedDisclaimerID) !== Number(disclaimer.id) &&
                        (_.isEmpty(termsCondition) ||
                            contactDetails.overrideTermsConditions === 1 ||
                            Number(contactDetails.lastAcceptedTermsConditionsID) === Number(termsCondition.id))
                    ) {
                        // If disclaimer exists and is not overridden + terms does not exist or is overriden
                        signedContract = {
                            disclaimerID: disclaimer.id,
                            disclaimerInitials: top,
                            disclaimerInitialedAt: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
                            termsConditionsAutoAccepted: 1,
                            termsConditionsAutoAcceptedAt: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
                        };
                        contact = {
                            lastAcceptedDisclaimerID: disclaimer.id,
                            termsConditionsAcceptedAt: moment().format('YYYY-MM-DDTHH:mm:00'),
                            overrideDisclaimer: contactDetails.overrideDisclaimer,
                            overrideTermsConditions: contactDetails.overrideTermsConditions,
                        };
                    } else if (
                        !_.isEmpty(termsCondition) &&
                        contactDetails.overrideTermsConditions === 0 &&
                        Number(contactDetails.lastAcceptedTermsConditionsID) !== Number(termsCondition.id) &&
                        (_.isEmpty(disclaimer) ||
                            contactDetails.overrideDisclaimer === 1 ||
                            Number(contactDetails.lastAcceptedDisclaimerID) === Number(disclaimer.id))
                    ) {
                        // If terms exists and is not overridden + disclaimer does not exist or is overrideden
                        signedContract = {
                            termsConditionsID: termsCondition.id,
                            disclaimerInitials: top,
                            disclaimerInitialedAt: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
                            disclaimerAutoAccepted: 1,
                            disclaimerAutoAcceptedAt: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
                        };
                        contact = {
                            lastAcceptedTermsConditionsID: termsCondition.id,
                            disclaimerLastAcceptedAt: moment().format('YYYY-MM-DDTHH:mm:00'),
                            overrideDisclaimer: contactDetails.overrideDisclaimer,
                            overrideTermsConditions: contactDetails.overrideTermsConditions,
                        };
                    }
                    setLoading(true);
                    if (contact) {
                        contact.companyId = companyId;
                        await editContactDetailsService(contact, contactDetails.id, companyId);
                    }
                    if (signedContract) {
                        let newContract = await addSignedContractService(signedContract, companyId);
                        history.push({
                            pathname: '/p/booking-confirmation',
                            search: `?bid=${bookingId}&ts=${bookingCreatedTimeStamp}`, // query string
                            state: {
                                // location state
                                signedContractId: newContract?.data?.id,
                            },
                        });
                    }
                }
                setLoading(false);
            } catch (e) {
                setLoading(false);
            }
        })();
    };
    useEffect(() => {
        // if (contactDetails && Object.keys(termsCondition).length > 0 && contactDetails.overrideTermsConditions === 0 && Number(contactDetails.lastAcceptedTermsConditionsID) !== Number(termsCondition.id) &&
        //     Object.keys(disclaimer).length > 0 && contactDetails.overrideDisclaimer === 0 && Number(contactDetails.lastAcceptedDisclaimerID) !== Number(disclaimer.id)) {
        //     if (top.length >= 2 && bottom.length >= 2) setDisable(false)
        //     else setDisable(true)
        // }
        // else if (contactDetails && Object.keys(termsCondition).length > 0 && contactDetails.overrideTermsConditions === 0 && Number(contactDetails.lastAcceptedTermsConditionsID) !== Number(termsCondition.id)) {
        //     if (bottom && bottom.length < 2) setDisable(true)
        //     else setDisable(false)
        // }
        // else if (Object.keys(disclaimer).length > 0 && contactDetails.overrideDisclaimer === 0 && Number(contactDetails.lastAcceptedDisclaimerID) !== Number(disclaimer.id)) {
        //     if (top && top.length < 2) setDisable(true)
        //     else setDisable(false)
        // }
        if (isDisclaimer && isTerms) {
            if (top.length >= 2 && bottom.length >= 2) setDisable(false);
            else setDisable(true);
        } else if (isDisclaimer) {
            if (top.length >= 2) setDisable(false);
            else setDisable(true);
        } else if (isTerms) {
            if (bottom.length >= 2) setDisable(false);
            else setDisable(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [top, bottom]);

    return showDisclaimer ? (
        <div>
            <>
                <div className="top-header">
                    <div
                        className="top-header-box d-flex justify-content-between align-items-center"
                        style={{ backgroundColor: `${tripSetting.sectionColor}` }}
                    >
                        <div className="booking-title text-white">
                            {tripSetting && tripSetting.companyLogoUrl ? (
                                <Image src={tripSetting.companyLogoUrl} className="images-logo m-0 p-0" />
                            ) : (
                                ''
                            )}
                            <div className="booking-heading">{`BOOKING ${bookingID}`}</div>
                        </div>
                        <div
                            className="btn text-white rounded-pill no-point"
                            style={{
                                backgroundColor: `${loading ? '#646262' : tripSetting.buttonColor}`,
                            }}
                        >
                            <i className="fas fa-check-square mr-2"></i>CONFIRM BOOKING
                        </div>
                    </div>
                    <div className="background-box-color">
                        <div className="background-wrapper" style={{ backgroundColor: `${tripSetting.sectionColor}` }}>
                            <Container fluid>
                                <>
                                    <Col xs={12} className="d-flex justify-content-center p-0">
                                        <div className="w-100 payment-form-main">
                                            <div
                                                className="payment-form-desc-main"
                                                style={{ backgroundColor: `${tripSetting.mainColor}` }}
                                            >
                                                {/* desclaimer logic */}
                                                {isDisclaimer ? (
                                                    <>
                                                        <Form.Label
                                                            className=" text-uppercase payment-form-headding mb-2"
                                                            style={{ color: `${tripSetting.textColor}` }}
                                                        >
                                                            Disclaimer
                                                        </Form.Label>

                                                        <div className="top-section">
                                                            <div
                                                                className="desclaimer-text text-white"
                                                                dangerouslySetInnerHTML={{ __html: disclaimer.html }}
                                                            />
                                                            <div className="initial-wrapper">
                                                                <h6 className="initial-text">
                                                                    Initial here to verify you agree to these terms:
                                                                </h6>
                                                                <div>
                                                                    <FormGroup className="">
                                                                        <div className="w-100 initial-input">
                                                                            <Form.Control
                                                                                type="text"
                                                                                id="topInitial"
                                                                                maxLength={3}
                                                                                minLength={2}
                                                                                name="topInitial"
                                                                                onChange={(e) => {
                                                                                    setTop(e.target.value);
                                                                                }}
                                                                                //{...register('topInitial', { required: true })}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}

                                                {/* terms & conditions logic */}
                                                {isTerms ? (
                                                    <>
                                                        <Form.Label
                                                            className=" text-uppercase payment-form-headding mb-2"
                                                            style={{ color: `${tripSetting.textColor}` }}
                                                        >
                                                            Terms & Conditions
                                                        </Form.Label>

                                                        <div className="bottom-section">
                                                            <div className="conditon-warpper scroll-icon">
                                                                <div
                                                                    className="terms-text break-word "
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: termsCondition.html,
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="mt-4">
                                                                <div className="initial-wrapper">
                                                                    <h6 className="initial-text">
                                                                        Initial here to verify you agree to these terms:
                                                                    </h6>
                                                                    <div>
                                                                        <FormGroup className="">
                                                                            <div className="w-100 initial-input">
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    id="bottomInitial"
                                                                                    maxLength={3}
                                                                                    minLength={2}
                                                                                    name="bottomInitial"
                                                                                    onChange={(e) => {
                                                                                        setBottom(e.target.value);
                                                                                    }}
                                                                                    //isInvalid={!bottom}
                                                                                    //{...register('topInitial', { required: true })}
                                                                                />
                                                                            </div>
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                </>
                            </Container>
                            <div className="btn-wrapper">
                                <div className="back-btn">
                                    <Button disabled={loading}>
                                        <i className="fas fa-fast-backward"></i> Back
                                    </Button>
                                </div>
                                <div className="continue-btn">
                                    <Button
                                        style={{
                                            backgroundColor: `${disable ? '#646262' : tripSetting.buttonColor}`,
                                            borderColor: 'transparent',
                                        }}
                                        disabled={disable}
                                        onClick={handleSave}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end p-4">
                    <Image
                        src={'https://storage.googleapis.com/the-bus-network-bucket/default/Powered-By-TBN-Logo.png'}
                        className="m-0 p-0"
                    />
                </div>
                {loading && <Loading loading={loading} />}
            </>
        </div>
    ) : (
        <>{loading && <Loading loading={loading} />}</>
    );
};

export default React.memo(BookingDesclaimer, () => true);
